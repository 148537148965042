import { NetworkError, ServiceError } from 'helpers/error-types'
import 'whatwg-fetch'
import queryString from 'query-string'
import paulLogInfo from '../utils/logger'

const DEFAULT_OPTIONS = {
  credentials: 'include',
  headers: {
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'Content-Type': 'application/json'
  },
  method: 'GET'
}

// TODO: including both for Kibana Log for now, depercate CC_LOADER ASAP
const triggerComponent = 'PAYMENT_COMPONENT_HTTP_HELPER'

export const callEndpoint = async (url, options = {}) => {
  let res
  try {
    res = await window.fetch(url, {
      ...DEFAULT_OPTIONS,
      ...options,
      headers: {
        ...DEFAULT_OPTIONS.headers,
        ...options.headers
      }
    })
  } catch (error) {
    paulLogInfo({
      triggerComponent,
      triggerEvent: 'callEndpoint',
      errors: error
    })
    throw new NetworkError(res)
  }
  try {
    res.json = await res.json()
  } catch (error) {
    paulLogInfo({
      triggerComponent,
      triggerEvent: 'callEndpoint',
      errors: error
    })
    res.json = null
  }
  if (!res.ok) {
    throw new ServiceError(res)
  }
  return res.json
}

export function addQueryParamsToURL (urlWithParams, newParams = {}, options = { overrideExisting: true }) {
  const { url, query } = queryString.parseUrl(urlWithParams)
  const params = options.overrideExisting ? Object.assign(query, newParams) : Object.assign(newParams, query)
  const newQueryString = queryString.stringify(params)
  const newURL = newQueryString ? `${url}?${newQueryString}` : url
  return newURL
}

export const getCheckoutPageHeaders = () => {
  if (window.location.pathname === '/checkout/r/payment' || window.location.pathname === '/checkout/r/fulfillment') {
    return 'classic-checkout'
  } else if (window.location.pathname === '/checkout/r/fast-track') {
    return 'fast-track'
  }
  return ''
}

export const getCookie = cname => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
