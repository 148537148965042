import React from 'react'
import PropTypes from 'prop-types'
import 'whatwg-fetch'
import _ from 'lodash'
import Paul from '../utils/logger'
import Errors from './errors/errors'
import { ServiceError } from '../helpers/error-types'
import { callEndpoint } from 'helpers/http'
import loadScript from 'helpers/load-script'

const CONFIG_PATH = `/payment/api/bootstrap`

export const GENERAL_ERROR = {
  errorCode: 'GENERAL_ERROR',
  errorMessage: 'Sorry, there was a problem. Please try that again.'
}
export const BOOTSTRAP_ERROR = {
  errorCode: 'BOOTSTRAP_ERROR',
  errorMessage: `It looks like we're having an issue loading this page. This issue should only be temporary, so please try again.`
}

const isErrorVisible = error => error.errorCode !== 'standardizationError'

export default class CreditCardLoader extends React.PureComponent {
  static propTypes = {
    isFormComplete: PropTypes.func,
    submitForm: PropTypes.bool,
    submitResult: PropTypes.func.isRequired,
    updateCardType: PropTypes.func,
    clientId: PropTypes.string,
    options: PropTypes.any,
    purpose: PropTypes.string,
    paymentReferenceId: PropTypes.string,
    disableMode: PropTypes.bool,
    demoBootstrap: PropTypes.object,
    /** prop passed into <Fieldset> component that contains "Payment Method" label */
    hidePaymentMethodLabel: PropTypes.bool
  }

  static getDerivedStateFromError (error) {
    Paul.error(error)
    return { errors: [BOOTSTRAP_ERROR], bubbledError: true }
  }

  constructor (props) {
    super(props)
    this.state = {
      Component: null,
      config: null,
      data: {},
      errors: [],
      bubbledError: false,
      purpose: props.purpose || null,
      paymentReferenceId: props.paymentReferenceId || null
    }
    this.submitResult = this.submitResult.bind(this)
  }

  async componentDidMount () {
    this.attachScript()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.submitForm && !prevProps.submitForm) {
      this.clearErrorState()
    }
  }

  render () {
    const props = this.props
    const { Component, errors } = this.state
    const showSpinner = !errors && !Component
    const visibleErrors = errors && errors.filter(isErrorVisible)

    return (
      <div>
        { showSpinner && (
          <i
            className="spinner"
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
        ) }
        { errors && errors.length > 0 && <Errors errors={ visibleErrors } /> }
        { !this.state.bubbledError && Component && (
          <Component
            { ...props }
            dataCallback={ this.dataCallback }
            config={ this.state.config }
            purpose={ this.state.purpose }
            submitResult={ this.submitResult }
            displayOnError={ this.displayOnError }
          />
        ) }
      </div>
    )
  }

  submitResult = data => {
    const errors = _.get(data, ['errors'], '')
    if (errors) {
      this.setState(() => ({ errors: errors }))
    }
    this.props.submitResult(data)
  }

  dataCallback = (data, isValid) => {
    this.props.isFormComplete(isValid)
    this.setState({ data })
  }

  displayOnError = (error = []) => {
    return error.length > 0 ? this.setState({ errors: error }) : null
  }

  async attachScript () {
    let config = void 0

    if (!this.props.demoBootstrap) {
      config = await this.getBootstrap()
    } else {
      config = this.props.demoBootstrap
    }
    // this new message caused tests to fail. updated tests accoridngly.
    if (!config || !config.metaLayer || !config.metaLayer.formName) {
      this.setErrorState({ errorMessage: `Unable to load component` })
      return
    }

    const formHostname = config.metaLayer.env_jsUrl || ''
    const formPath = config.metaLayer.formName

    try {
      const Component = await loadScript(formHostname, formPath)
      this.setState({ Component, config })
    } catch (ex) {
      if (ex && ex.message) {
        this.setErrorState({ errorCode: 'FAIL_REGISTER', errorMessage: ex.message })
      } else {
        this.setErrorState(BOOTSTRAP_ERROR)
      }
    }
  }

  getBootstrap = async () => {
    const clientId = this.props.clientId || ''
    const paymentReferenceId = this.props.paymentReferenceId
      ? `&paymentReferenceId=${this.props.paymentReferenceId}`
      : ''

    const bootstrapUrl = CONFIG_PATH + '?payment=CC' + paymentReferenceId
    try {
      return await callEndpoint(bootstrapUrl, {
        headers: {
          'x-client': clientId
        }
      })
    } catch (e) {
      if (e instanceof ServiceError) {
        this.setErrorState(BOOTSTRAP_ERROR)
      } else {
        this.setErrorState(GENERAL_ERROR)
      }
    }
  }

  /**
   * @param {*} resultJson - Extract paymentReferenceId from json and store in state
   */
  updatePaymentReferenceId (resultJson) {
    if (resultJson && resultJson.creditCard && resultJson.creditCard.paymentReferenceId) {
      this.setState(() => ({ paymentReferenceId: resultJson.creditCard.paymentReferenceId }))
    }
  }

  /**
   * @param error Error object such as { errorCode: 'FAIL_REGISTER', errorMessage: 'Error loading credit card form.' }
   */
  setErrorState (error) {
    this.setState(() => ({ errors: [error] }))
  }

  /**
   * Remove all error messages
   */
  clearErrorState () {
    this.setState(() => ({ errors: null }))
  }
}
