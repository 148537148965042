import { paulLogWarn } from '../utils/logger'

export default (formHostname, formPath) => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.src = `${formHostname}${formPath}`
  script.async = true
  const triggerComponent = 'LOAD_SCRIPT'
  script.onload = () => {
    const scriptSrc = 'index'
    const windowSrc = window[scriptSrc]
    if (!windowSrc) {
      paulLogWarn({
        triggerComponent,
        triggerEvent: 'SCRIPT_ON_LOAD | WINDOW_INDEX_DOES_NOT_EXIST',
      })
      return reject(new TypeError(`Unable to register component ${scriptSrc}`))
    }
    const component = windowSrc.default
    if (!component) {
      paulLogWarn({
        triggerComponent,
        triggerEvent: 'SCRIPT_ON_LOAD | FAILED_TO_LOAD_DEFAULT_COMPONENT',
      })
      return reject(new TypeError(`Unable to register default component ${scriptSrc}`))
    }
    resolve(component)
  }
  script.onerror = reject

  document.body.appendChild(script)
})
