(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("PropTypes"), require("brix"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "PropTypes", "brix"], factory);
	else if(typeof exports === 'object')
		exports["index"] = factory(require("React"), require("PropTypes"), require("brix"));
	else
		root["index"] = factory(root["React"], root["PropTypes"], root["brix"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__397__, __WEBPACK_EXTERNAL_MODULE__413__) => {
return 