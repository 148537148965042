import { callEndpoint, getCheckoutPageHeaders, getCookie } from '../helpers/http'
import { ASSETS_URL } from './payment-component-loader'
import paulLogWarn from '../utils/logger'
import loadScript from 'helpers/load-script'
import { fetchManagementEventsApi } from '../utils/management-events'

// TODO: including both for Kibana Log for now, depercate CC_LOADER ASAP
const triggerComponent = 'CREDIT_CARD_LOADER | CC_LOADER'

const loaderLog = (actionText) =>
  ({
    'action': 'LOG',
    'event': {
      'level': 'WARN',
      'action': actionText,
    }
  })

export const getAssetAndIndexPreLoad = (clientId, orderId) => {
  try {
    const data = getAssetPreLoad(clientId).then(res => setIndexPreLoad(res))
    return data
  } catch (error) {
    // TODO: fetchManagementEventApi needs to be verified with associated failed call
    fetchManagementEventsApi(clientId, orderId, loaderLog('GET_ASSET_AND_INDEX_PRELOAD'))
    paulLogWarn({
      message: 'PRELOAD_ERROR',
      triggerComponent,
      triggerEvent: 'getAssetAndIndexPreLoad',
      errors: error
    })
  }
}

export const getAssetPreLoad = async (clientId, orderId) => {
  const queryParam = clientId ? `?client=${clientId}` : ''
  const assetsUrl = ASSETS_URL + queryParam

  try {
    return await callEndpoint(assetsUrl, {
      headers: {
        'x-pay-visibility-clientpage': getCheckoutPageHeaders(),
        'x-client': clientId,
        'x-pay-visibility-ctt': getCookie('CTT'),
        'x-pay-visibility-user-agent': navigator.userAgent
      }
    })
  } catch (error) {
    // TODO: fetchManagementEventApi needs to be verified with associated failed call
    fetchManagementEventsApi(clientId, orderId, loaderLog('GET_ASSET_PRELOAD'))
    paulLogWarn({
      message: 'ASSETS_CALL_FAILURE_PRE_LOAD',
      triggerComponent,
      triggerEvent: 'getAssetPreLoad',
      errors: error
    })
  }
}

export const setIndexPreLoad = async (clientId, orderId, config) => {
  // this new message caused tests to fail. updated tests accoridngly.
  const formHostname = config.metaLayer.env_jsUrl || ''
  const formPath = config.metaLayer.formName

  try {
    const Component = await loadScript(formHostname, formPath)

    return Component
  } catch (error) {
    // TODO: fetchManagementEventApi needs to be verified with associated failed call
    fetchManagementEventsApi(clientId, orderId, loaderLog('SET_INDEX_PRELOAD'))
    paulLogWarn({
      message: 'SET_INDEX_FAILURE_PRE_LOAD',
      triggerComponent,
      triggerEvent: 'setIndexPreLoad',
      errors: error
    })
  }
}
