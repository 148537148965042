import React, { PureComponent } from 'react'
import { BrixAlert } from 'sc-pattern-library'
const WARNING_SEVERITY = 'warning'
const INFO_SEVERITY = 'info'
const DANGER_SEVERITY = 'danger'
const ERROR_TYPES = {
  TAX_ESTIMATE_ERROR: WARNING_SEVERITY,
  TECSYSDOWN: WARNING_SEVERITY,
  ORDER_TAX_ERROR: WARNING_SEVERITY,
  UI_ADDRESS_2_MISSING: WARNING_SEVERITY,
  UI_LOS_CHANGED: INFO_SEVERITY,
  UI_PICKUP_CHANGED_DATE: INFO_SEVERITY,
  UI_PICKUP_CHANGED_DATE_IN_PAYMENT_PAGE: INFO_SEVERITY,
  UI_SHIPPING_CHANGED: INFO_SEVERITY,
  REGIONAL_RESERVATION_FAILS: WARNING_SEVERITY,
  UI_SUBSCRIPTION_PAYMENT_NEEDED: WARNING_SEVERITY,
  UI_INSTALL_REMOVED: WARNING_SEVERITY,
  UI_INSTALL_AVAILABLE: INFO_SEVERITY,
  UI_CARD_CHANGED: WARNING_SEVERITY,
  CART_RESERVE_CHECKOUT_SETUP_PICKUP_FAILS: WARNING_SEVERITY,
  CART_RESERVE_CHECKOUT_SETUP_SHIPPING_FAILS: WARNING_SEVERITY,
  CART_RESERVE_CHECKOUT_SWITCH_TO_SHIPPING: WARNING_SEVERITY,
  CART_RESERVE_CHECKOUT_SWITCH_TO_PICKUP: WARNING_SEVERITY,
  CART_RESERVE_CHECKOUT_HD_SELECTED_STORE_UNAVAILABLE: WARNING_SEVERITY,
  CART_RESERVE_REGIONAL_RESERVATION_FAILS: WARNING_SEVERITY,
  RWZ_CERTS_NOT_ALLOWED: WARNING_SEVERITY
}

class ErrorBox extends PureComponent {
  componentDidMount () {
    scrollToError(this.alert)
  }

  render () {
    const { error } = this.props
    const code = error.errorCode || ''
    return (
      <div
        style={{ marginBottom: '25px' }}
        className="error-box"
        tabIndex="-1"
        ref={ this.setAlertRef }
      >
        <BrixAlert useUpdatedVersion={ true } level={ ERROR_TYPES[code] || DANGER_SEVERITY } isVisible={ true } role="alert" ariaLabel="error-alert">
          <div className="error-spacing">
            <div ref={ this.setElementRef }>{ error.errorMessage || '' }</div>
          </div>
        </BrixAlert>
      </div>
    )
  }

  setElementRef = el => {
    this.element = el
  }

  setAlertRef = el => {
    this.alert = el
  }
}

const scrollToError = element => {
  if (element) {
    const offsetTop = window.pageYOffset + element.getBoundingClientRect().top
    window.scroll(0, offsetTop - element.clientHeight)
  }
}

export default ErrorBox
