import LoggerConsole from 'sc-paul/javascript/loggers/logger-console'
import LoggerRemote, { PROD_URL, TEST_URL } from 'sc-paul/javascript/loggers/logger-remote'
import pjson from '../../package.json'
import { getCookie } from '../helpers/http'
import { getBrowser } from '../helpers/logger-helpers.js'

// eslint-disable-next-line no-console
const domainUrl = window.location.href
const url = (domainUrl?.toLowerCase().includes('www.bestbuy.com') || domainUrl?.toLowerCase().includes('www.bestbuyfoundation.org')) ? PROD_URL : TEST_URL

// eslint-disable-next-line no-console
const loggerConsole = new LoggerConsole()
const loggerRemote = new LoggerRemote()

loggerConsole.config({ logLevel: 'debug' })
loggerRemote.config({ logLevel: 'info', url })
loggerConsole.pipe(loggerRemote) // TODO: this could be a way to incorporate our own/new tagging endpoint

// default log data attributes
const FEVer = ` | FEVersion: ${pjson.version}`
const CTT = ` | CTT: ${getCookie('CTT')}`
const clientId = ` | clientId: ${getCookie('PAYMENT_COMPONENT_CLIENTID')}`
const userAgent = ` | userAgent: ${getBrowser()}`

/** DEFAULT LOG DATA: all default log attributes (above) collected here & added to ALL paul logs */
const defaultLogData = FEVer + CTT + clientId + userAgent

/** logData attributes: (passed in log methods as param object)
  * message: string value we have (always) passed (same as logInfo in above method)
  * order: string
  * errors: array (eventually pass inline errors through this method)
  * triggerComponent: string (use this to identify the component/file the log is coming from)
  * triggerEvent: string (use this to name/describe event log is originating from)
  * vendor: string (specific to BNPL for now)
 */
const formatLogData = logData => {
  const appName = 'PAYMENTS EXPERIENCE | PAYMENT_COMPONENT | LOADER | '
  const message = logData?.message ? `LOG_MESSAGE: ${logData.message}` : ''
  const orderId = logData?.orderId ? ` | ORDER_ID: ${logData.orderId}` : ''
  const triggerComponent = logData?.triggerComponent ? ` | TRIGGER_COMPONENT: ${logData.triggerComponent}` : ''
  const triggerEvent = logData?.triggerEvent ? ` | TRIGGER_EVENT: ${logData.triggerEvent}` : ''
  const errors = logData?.errors ? ` | ERRORS: ${JSON.stringify(logData.errors)}` : ''
  const vendor = logData?.vendor ? ` | VENDOR: ${logData.vendor}` : ''

  return appName + message + orderId + triggerComponent + triggerEvent + errors + vendor
}

/** INFO LEVEL METHOD ***************** */
export const paulLogInfo = logData => {
  const formattedLogData = formatLogData(logData)

  loggerConsole.info(formattedLogData + defaultLogData)
}

/** WARN LEVEL METHOD ***************** */
export const paulLogWarn = logData => {
  const formattedLogData = formatLogData(logData)

  loggerConsole.warn(formattedLogData + defaultLogData)
}

// TODO: let's start to deprecate this (default) method
// both methods are currently/still supported
export default {
  log: (logInfo, ...rest) => loggerConsole.log(logInfo + defaultLogData, ...rest),
  debug: (logInfo, ...rest) => loggerConsole.debug(logInfo + defaultLogData, ...rest),
  info: (logInfo, ...rest) => loggerConsole.info(logInfo + defaultLogData, ...rest),
  warn: (logInfo, ...rest) => loggerConsole.warn(logInfo + defaultLogData, ...rest),
  error: (logInfo, ...rest) => loggerConsole.error(logInfo + defaultLogData, ...rest), // this should not be used for now-- it is apparently unreliable atm
}

// TODO: get this set to be on/off switch
// const getLogLevel = () => {
//   if (process.env.NODE_ENV === 'development') {
//     return 'debug'
//   }
//   return false
// }

// loggerConsole.config({
//   logLevel: getLogLevel()
// }))
