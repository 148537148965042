import { callEndpoint, getCookie } from '../helpers/http'
import { paulLogWarn, paulLogInfo } from './logger'

const EVENT_URL = `/payment/api/v3/events`
const triggerComponent = 'MANAGEMENT_EVENTS'

export const fetchManagementEventsApi = async (clientId, orderId, params) => {
  const { ...body } = params
  try {
    paulLogInfo({
      message: `REQUEST BODY: ${JSON.stringify(body)}`,
      orderId,
      triggerComponent,
      triggerEvent: 'FETCH_MANAGEMENT_EVENTS_API_CALL',
    })
    return await callEndpoint(EVENT_URL, {
      method: 'POST',
      headers: {
        // TODO: verify where we header data should be sourcing
        'x-context-id': orderId || getCookie('SID'),
        'x-client': clientId,
        'ORDER_TOKEN': orderId,
        'x-pay-visibility-ctt': getCookie('CTT'),
        'x-pay-visibility-user-agent': navigator.userAgent
      },
      body: JSON.stringify(body),
    })
  } catch (error) {
    paulLogWarn({
      orderId,
      triggerComponent,
      triggerEvent: 'FETCH_MANAGEMENT_EVENTS_API_ERROR',
      errors: error
    })
  }
}
