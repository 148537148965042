import LoggerConsole from 'sc-paul/javascript/loggers/logger-console'

const log = new LoggerConsole()

const getLogLevel = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'debug'
  }
  return false
}

log.config({
  logLevel: getLogLevel()
})

export default log
