export function ServiceError (res) {
  Object.setPrototypeOf(this.constructor.prototype, Error.prototype)
  Error.captureStackTrace && Error.captureStackTrace(this, this.constructor)
  this.name = this.constructor.name
  this.json = res.json
  this.status = res.status
  this.url = res.url
  this.ok = res.ok
  this.message = `Sorry, there was a problem. Please try that again.`
}

export function NetworkError (res) {
  Object.setPrototypeOf(this.constructor.prototype, Error.prototype)
  Error.captureStackTrace && Error.captureStackTrace(this, this.constructor)
  this.name = this.constructor.name
  this.message = `Request failed because of network connection`
}
