export default (formHostname, formPath) => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.src = `${formHostname}${formPath}`
  script.async = true
  script.onload = () => {
    const reHash = /(-[0-9a-fA-F]+)?\.js/ // fingerprint hash and extension to remove
    /** this path needs to match file path in bootstrap-response.json file for local instance to render properly */
    const scriptSrc = formPath.replace(reHash, '').replace('dist/', '')
    const windowSrc = window[scriptSrc]
    if (!windowSrc) {
      return reject(new TypeError(`Unable to register component ${scriptSrc}`))
    }
    const component = windowSrc.default
    if (!component) {
      return reject(new TypeError(`Unable to register default component ${scriptSrc}`))
    }
    resolve(component)
  }
  script.onerror = reject

  document.body.appendChild(script)
})
