import React from 'react'
import PropTypes from 'prop-types'
import 'whatwg-fetch'
import paul, { paulLogWarn, paulLogInfo } from '../utils/logger'
import Errors from './errors/errors'
import { ServiceError } from '../helpers/error-types'
import { callEndpoint } from 'helpers/http'
import loadScript from 'helpers/load-script'
import { getCookie, getCheckoutPageHeaders } from '../helpers/http'
import { fetchManagementEventsApi } from '../utils/management-events'

// https://code.bestbuy.com/wiki/display/PAY/V3+-+Bootstrap+-+Managed+Payments
const BOOTSTRAP_URL = paymentId => `/payment/api/v3/payment/${paymentId}/bootstrap`
export const ASSETS_URL = `/payment/api/v3/payment/getAsset`

export const GENERAL_ERROR = {
  errorCode: 'GENERAL_ERROR',
  errorMessage: 'Sorry, there was a problem. Please try that again.'
}
export const BOOTSTRAP_ERROR = {
  errorCode: 'BOOTSTRAP_ERROR',
  errorMessage: `It looks like we're having an issue loading this page. This issue should only be temporary, so please try again.`
}

const loaderLog = (actionText) =>
  ({
    'action': 'LOG',
    'event': {
      'level': 'WARN',
      'action': actionText,
    }
  })

// TODO: including both for Kibana Log for now, depercate CC_LOADER ASAP
const triggerComponent = 'CREDIT_CARD_LOADER | CC_LOADER'

const isErrorVisible = error => error.errorCode !== 'standardizationError'

export default class PaymentComponentLoader extends React.PureComponent {
  static getDerivedStateFromError (error) {
    error && paul.warn(
      `PAYMENT_COMPONENT | CC_LOADER | NETWORK_ERROR | getDerivedStateFromError: ${JSON.stringify(error)}`
    )
    return { errors: [BOOTSTRAP_ERROR], bubbledError: true }
  }

  static propTypes = {
    submitForm: PropTypes.bool,
    onSubmitFormComplete: PropTypes.func.isRequired,
    onUnauthorized: PropTypes.bool,
    clientId: PropTypes.string,
    orderToken: PropTypes.string,
    features: PropTypes.object,
    purpose: PropTypes.string,
    paymentReferenceId: PropTypes.string,
    paymentId: PropTypes.string,
    disableMode: PropTypes.bool,
    demoBootstrap: PropTypes.object,
    creditCard: {
      paymentMethodLabel: PropTypes.bool
    }
  }

  constructor (props) {
    super(props)

    this.state = {
      Component: window['index']?.default || null,
      config: null,
      data: {},
      errors: null,
      bubbledError: false,
      purpose: props.purpose || null,
      paymentReferenceId: props.paymentReferenceId || null
    }
  }

  async componentDidMount () {
    const clientId = this.props.clientId || 'NO_CLIENT_ID_AVAILABLE'
    const orderId = this.props.orderId || 'NO_ORDER_ID_AVAILABLE'
    const logInfo = JSON.parse(JSON.stringify(this.props))
    if (logInfo?.orderData?.dayPhone) logInfo.orderData.dayPhone = 'XXXXXXXXXX'
    paulLogInfo({
      message: `${JSON.stringify(logInfo)}`,
      triggerComponent,
      triggerEvent: 'componentDidMount'
    })
    fetchManagementEventsApi(clientId, orderId, loaderLog('COMPONENT_DID_MOUNT'))
    // Setting the client Id as a cookie to access it throughout the application
    if (this.props.clientId) document.cookie = `PAYMENT_COMPONENT_CLIENTID=${clientId}`
    this.attachScript()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.submitForm && !prevProps.submitForm) {
      this.clearErrorState()
    }
  }

  render () {
    const props = this.props
    const { Component, errors, config } = this.state
    // isLoading value should toggle loading view || component view
    const isLoading = !errors && !Component && !config
    const visibleErrors = errors && errors.filter(isErrorVisible)
    const paymentComponentLoader = (
      <Component
        {...props}
        config={this.state.config}
        purpose={this.state.purpose}
        displayOnError={this.displayOnError}
      />
    )

    return (
      <div className='loader-wrapper'>
        { errors && errors.length > 0 && <Errors errors={visibleErrors} /> }
        { isLoading ? (
          <i
            className='a-skeleton-shimmer'
            style={{ display: 'block', width: '100%', height: '200px', marginLeft: 'auto', marginRight: 'auto' }}
          />
        ) :
          !this.state.bubbledError && Component && this.state.config && paymentComponentLoader
        }
      </div>
    )
  }

  displayOnError = (error = []) => {
    return error.length > 0 ? this.setState({ errors: error }) : null
  }

  // takes in bootstrap response data and makes call for index js assets
  // config will be bootstrap res, passed/called from this.attachScript
  getComponent = async () => {
    const clientId = this.props.clientId || ''
    const queryParam = clientId && `?client=${clientId}`
    const assetsUrl = ASSETS_URL + queryParam
    const orderId = this.props.orderId

    try {
      return await callEndpoint(assetsUrl, {
        headers: {
          'x-pay-visibility-clientpage': getCheckoutPageHeaders(),
          'x-client': clientId,
          'x-pay-visibility-ctt': getCookie('CTT'),
          'x-pay-visibility-user-agent': navigator.userAgent
        }
      })
    } catch (error) {
      // TODO: fetchManagementEventApi needs to be verified with associated failed call
      fetchManagementEventsApi(clientId, orderId, loaderLog('GET_COMPONENT'))

      this.props.onRendered && this.props.onRendered(false)
      paulLogWarn({
        message: 'ASSETS_CALL_FAILURE',
        triggerComponent,
        triggerEvent: 'getComponent',
        errors: error
      })
      if (error instanceof ServiceError) {
        this.setErrorState(BOOTSTRAP_ERROR)
      } else {
        this.setErrorState(GENERAL_ERROR)
      }
    }
  }

  // handles bootstrap call
  attachScript () {
    const clientId = this.props.clientId
    const orderId = this.props.orderId

    try {
      if (!this.state.Component) {
        this.getComponent().then(res => this.setIndex(res))
      }
      this.getBootstrap().then(res => this.setConfig(res))
    } catch (error) {
      // TODO: fetchManagementEventApi needs to be verified with associated failed call
      fetchManagementEventsApi(clientId, orderId, loaderLog('ATTACH_SCRIPT'))
      paulLogWarn({
        message: 'ATTACH_SCRIPT_FAILURE ',
        triggerComponent,
        triggerEvent: 'attachScript',
        errors: error
      })
    }
  }

  setConfig = config => {
    this.setState({ config })
  }

  setIndex = async config => {
    const clientId = this.props.clientId
    const orderId = this.props.orderId

    // this new message caused tests to fail. updated tests accoridngly.
    if (!config || !config.metaLayer || !config.metaLayer.formName) {
      this.setErrorState({ errorMessage: `Unable to load component` })
      return
    }

    const formHostname = config.metaLayer.env_jsUrl || ''
    const formPath = config.metaLayer.formName

    try {
      const Component = await loadScript(formHostname, formPath)
      this.setState({ Component })
    } catch (error) {
      // TODO: fetchManagementEventApi needs to be verified with associated failed call
      fetchManagementEventsApi(clientId, orderId, loaderLog('SET_INDEX'))

      this.props.onRendered && this.props.onRendered(false)
      paulLogWarn({
        message: 'SET_INDEX_FAILURE',
        triggerComponent,
        triggerEvent: 'setIndex',
        errors: error
      })
      if (error && error.message) {
        this.setErrorState({ errorCode: 'FAIL_REGISTER', errorMessage: error.message })
      } else {
        this.setErrorState(BOOTSTRAP_ERROR)
      }
    }
  }

  getBootstrap = async () => {
    const clientId = this.props.clientId || ''
    const orderToken = this.props.orderToken || ''
    const paymentId = this.props.paymentId || ''
    const orderId = this.props.orderId
    const bootstrapUrl = BOOTSTRAP_URL(paymentId)
    const isNoCardDataView = this.props.isNoCardDataView
    const headers = {
      'x-pay-visibility-clientpage': getCheckoutPageHeaders(),
      'x-client': clientId,
      'ORDER_TOKEN': orderToken,
      'x-pay-visibility-ctt': getCookie('CTT'),
      'x-pay-visibility-user-agent': navigator.userAgent
    }
    try {
      return await callEndpoint(bootstrapUrl, {
        headers: isNoCardDataView ? {
          'isNoCardDataView': isNoCardDataView,
          ...headers
        } : headers
      })
    } catch (error) {
      if (!error.ok && error.status === 401) {
        // TODO: fetchManagementEventApi needs to be verified with associated failed call
        fetchManagementEventsApi(clientId, orderId, loaderLog('GET_BOOTSTRAP'))
        paulLogWarn({
          message: `BOOTSTRAP_CALL_FAILED_UNAUTHORIZED | Setting callback onUnauthorized=true`,
          triggerComponent,
          triggerEvent: 'getBootstrap',
          errors: error
        })
        // paul.warn(`PAYMENT_COMPONENT | CC_LOADER | BOOTSTRAP_CALL_FAILED_UNAUTHORIZED | Setting callback onUnauthorized=true`)
        // This callback notifies parent page when customer has insufficient permission to access payment details.
        // Parent page can consider to redirect the customer to page where they can retain all the access.
        // In checkout scenario, Parent page takes the customer to Cart page.
        this.props.onUnauthorized && this.props.onUnauthorized(true)
      }

      this.props.onRendered && this.props.onRendered(false)
      paulLogWarn({
        message: 'BOOTSTRAP_CALL_FAILURE',
        triggerComponent,
        triggerEvent: 'getBootstrap',
        errors: error
      })
      if (error instanceof ServiceError) {
        this.setErrorState(BOOTSTRAP_ERROR)
      } else {
        this.setErrorState(GENERAL_ERROR)
      }
    }
  }

  /**
   * @param {*} resultJson - Extract paymentReferenceId from json and store in state
   */
  updatePaymentReferenceId (resultJson) {
    if (resultJson && resultJson.creditCard && resultJson.creditCard.paymentReferenceId) {
      this.setState(() => ({ paymentReferenceId: resultJson.creditCard.paymentReferenceId }))
    }
  }

  /**
   * @param error Error object such as { errorCode: 'FAIL_REGISTER', errorMessage: 'Error loading credit card form.' }
   */
  setErrorState (error) {
    this.setState(() => ({ errors: [error] }))
  }

  /**
   * Remove all error messages
   */
  clearErrorState () {
    this.setState(() => ({ errors: null }))
  }
}
