import { NetworkError, ServiceError } from 'helpers/error-types'
import 'whatwg-fetch'
import queryString from 'query-string'

const DEFAULT_OPTIONS = {
  credentials: 'include',
  headers: {
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'Content-Type': 'application/json'
  },
  method: 'GET'
}

export const callEndpoint = async (url, options = {}) => {
  let res
  try {
    res = await window.fetch(url, {
      ...DEFAULT_OPTIONS,
      ...options,
      headers: {
        ...DEFAULT_OPTIONS.headers,
        ...options.headers
      }
    })
  } catch (e) {
    throw new NetworkError(res)
  }
  try {
    res.json = await res.json()
  } catch (ex) {
    res.json = null
  }
  if (!res.ok) {
    throw new ServiceError(res)
  }
  return res.json
}

export function addQueryParamsToURL (urlWithParams, newParams = {}, options = { overrideExisting: true }) {
  const { url, query } = queryString.parseUrl(urlWithParams)
  const params = options.overrideExisting ? Object.assign(query, newParams) : Object.assign(newParams, query)
  const newQueryString = queryString.stringify(params)
  const newURL = newQueryString ? `${url}?${newQueryString}` : url
  return newURL
}
