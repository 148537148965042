import React from 'react'
import PaymentComponentLoader from './components/payment-component-loader'
import { getAssetAndIndexPreLoad } from './components/get-asset-init'
import { paulLogInfo } from './utils/logger'

// TODO: including both for Kibana Log for now, depercate CC_LOADER ASAP
const triggerComponent = 'CREDIT_CARD_LOADER | CC_LOADER'

export const loadPaymentComponentAsset = (clientId) => {
  if (!window['index']?.default) {
    getAssetAndIndexPreLoad(clientId)
    paulLogInfo({
      message: 'LOAD_PAYMENT_COMPONENT_ASSET_PRE_LOAD | GET_ASSET_INDEX_TRIGGERED ',
      triggerComponent,
      triggerEvent: 'loadPaymentComponentAsset',
    })
  } else {
    paulLogInfo({
      message: 'LOAD_PAYMENT_COMPONENT_ASSET_PRE_LOAD | INDEX_EXISTS',
      triggerComponent,
      triggerEvent: 'loadPaymentComponentAsset',
    })
  }
}

export default function PaymentComponent (props) {
  return (
    <React.Fragment>
      <PaymentComponentLoader
        {...props}
      />
    </React.Fragment>
  )
}
