import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ErrorBox from 'components/errors/error-box'

export class Errors extends PureComponent {
  static propTypes = {
    errors: PropTypes.array.isRequired,
  }

  static defaultProps = {
    className: 'section-errors'
  }

  render () {
    const { errors, className } = this.props
    return (
      <div role='alert' className={className}>
        { errors && errors.map((error, key) =>
          <ErrorBox key={key} error={error} />
        ) }
      </div>
    )
  }
}

export default Errors
